export const useHeaderStore = defineStore({
  id: "headerStore",
  state: () => {
    return {
      modalMenu: {
        active: false,
      },
      modalCatalog: {
        active: false,
      },
      height: {
        header: 0,
        additionalInfo: 0,
      },
    };
  },
  actions: {
    activeModal(state) {
      this.modalMenu.active = state;

      state
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "auto");
    },
    activeCatalog(state) {
      this.modalCatalog.active = state;
    },
    setHeightHeader(height) {
      this.height.header = height;
    },
    setAdditionalInfo(height) {
      this.height.additionalInfo = height;
    },
  },
  getters: {
    getModalMenu: (state) => state.modalMenu,
    getModalCatalog: (state) => state.modalCatalog,
    getHeightHeader: (state) => state.height,
  },
});
